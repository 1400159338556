import styled, { css } from 'styled-components';
import { spacer, zIndex } from 'theme';
import CarouselArrow from '../../Slider/CarouselArrow/CarouselArrow';

interface StyledSearchProductSliderProps {
  noMargin?: boolean;
}

export const StyledSearchProductSlider = styled.div<StyledSearchProductSliderProps>`
  position: relative;
  margin: ${({ noMargin }) => (noMargin ? 0 : spacer(150))};
`;

const StyledSearchCarouselArrowStyling = css`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: ${zIndex('base')};
`;

export const StyledSearchCarouselArrowLeft = styled(CarouselArrow)`
  ${StyledSearchCarouselArrowStyling};
  left: ${spacer(125)};
`;

export const StyledSearchCarouselArrowRight = styled(CarouselArrow)`
  ${StyledSearchCarouselArrowStyling};
  right: ${spacer(125)};
`;
