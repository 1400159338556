import { SlidesObject } from 'components/Slider/SliderEmbla/SliderEmbla.styled';
import { EmblaCarouselType } from 'embla-carousel';
import { useProductSelector } from 'features';
import useRouter from 'hooks/useRouter';
import { Ref } from 'react';
import { IGroupingProducts } from 'types/ContentfulTypes';
import { shuffleArray } from 'utils/arrayUtil';
import ProductsSlider, { DEFAULT_SLIDES_OBJECT } from '../../../Product/ProductsSlider/ProductsSlider';
import SearchProductSlider from '../../../Product/SearchProductSlider/SearchProductSlider';
import { SEARCH_TYPES } from '../types';
import { ProductCarouselLink } from './ContentfulProductCarousel.styled';

interface ContentfulProductCarouselProps {
  className?: string;
  content: IGroupingProducts;
  nested?: boolean;
  productSliderRef?: Ref<EmblaCarouselType>;
  showAmount?: boolean;
  slidesObject?: SlidesObject;
}

const ContentfulProductCarousel = ({
  className,
  content,
  nested,
  productSliderRef,
  showAmount,
  slidesObject = DEFAULT_SLIDES_OBJECT,
}: ContentfulProductCarouselProps) => {
  const { locale, locales } = useRouter();

  const {
    callToAction,
    internalLabel,
    products: productSelector,
    randomizeProductsOrder,
    showSignings,
    title,
  } = content.fields;
  const productSelectorProducts = productSelector?.fields?.products;

  const categoryCode = productSelectorProducts?.categoryCode?.length > 0 && productSelectorProducts?.categoryCode;
  const searchQuery = productSelectorProducts?.searchQuery;
  const searchType = productSelectorProducts?.searchType || SEARCH_TYPES.PRODUCT;
  const searchQueryLocale = locales?.find((loc) => searchQuery?.[loc]);
  const localizedSearchQuery = searchQuery?.[locale] || (searchQueryLocale && searchQuery?.[searchQueryLocale]);
  const { pushCnCToBack, showOutOfStockProducts } = productSelector.fields;

  const { data: products, isFetching } = useProductSelector({ selector: productSelector });

  const sliderProducts = randomizeProductsOrder ? shuffleArray(products) : products;

  const sliderButton = callToAction?.fields?.link && (
    <ProductCarouselLink
      content={callToAction}
      iconProps={{ name: 'angle-right', size: 125, type: 'custom' }}
      type={callToAction?.fields?.type}
    />
  );

  if (searchType === SEARCH_TYPES.PRODUCT) {
    return (
      <div id={content.sys?.id}>
        <ProductsSlider
          button={sliderButton}
          className={`product-slider ${className}`}
          isLoading={isFetching}
          noMargin
          products={sliderProducts}
          showAmount={showAmount}
          showCashBack
          slidesObject={slidesObject}
          title={nested ? '' : title}
          showSignings={showSignings}
          productSliderRef={productSliderRef}
        />
      </div>
    );
  }

  if ((localizedSearchQuery || categoryCode) && internalLabel) {
    return (
      <div id={content.sys?.id}>
        <SearchProductSlider
          callToActionComponent={sliderButton}
          className={`product-slider ${className}`}
          noMargin
          query={localizedSearchQuery || categoryCode}
          showAmount={showAmount}
          title={nested ? '' : title}
          slidesObject={slidesObject}
          showSignings={showSignings}
          pushCnCToBack={pushCnCToBack}
          showOutOfStockProducts={showOutOfStockProducts}
          randomizeProductsOrder={randomizeProductsOrder}
          productSliderRef={productSliderRef}
        />
      </div>
    );
  }

  return null;
};

export default ContentfulProductCarousel;
